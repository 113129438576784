import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { phonePrint } from '@pv/common/utils';

interface ContactInfoProps {
  fullName?: string;
  email?: string;
  phone?: string;
  accountName?: string;
}

const ContactInfo = ({
  fullName,
  email,
  phone,
  accountName,
}: ContactInfoProps) => {
  return (
    <Box
      sx={{
        display: 'block',
        width: '226px',
      }}
    >
      <Typography noWrap variant="subtitle1">
        <strong>{fullName}</strong>
      </Typography>
      <Typography variant="body2" noWrap>
        {email}
      </Typography>
      <Link underline="hover" href={`tel:${phone}`}>
        <Typography variant="body2" noWrap>
          {phonePrint(phone)}
        </Typography>
      </Link>
      <Typography variant="body2" noWrap>
        {accountName}
      </Typography>
    </Box>
  );
};

export default ContactInfo;
