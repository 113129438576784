import React, { Suspense } from 'react';
import {
  Navigate,
  Route,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {
  ErrorBoundary,
  PageLoading,
  RemoteRefreshTokenWatcher,
} from '@pv/common/components';
import {
  DatePickerProvider,
  PublicConfigProvider,
  ServerStateProvider,
  ThemeProvider,
} from '@pv/common/providers';
import { PVSnackbarProvider } from './providers/snackbar';
import { lazyImport } from '@pv/common/utils';
import { SentryUser } from '@pv/common/sentry';

import SignInPage from './pages/Auth/SignIn';
import SignUpPage from './pages/Auth/SignUp';
import NewUserPage from './pages/Auth/NewUser';
import NewPasswordPage from './pages/Auth/NewPassword';
import EditPasswordPage from './pages/Auth/EditPassword';
import PasswordInstructionsPage from './pages/Auth/PasswordInstructions';
import AcceptInvitationPage from './pages/Auth/AcceptInvitation';
import HubSpot from './components/HubSpot';
import FlashSnackbars from './components/FlashSnackbars';
import { AuthUserProvider } from './providers/auth';
import VenuesProvider from './providers/venues';
import DashboardLayout from './components/DashboardLayout';
import Auth from './pages/Auth';
import RestoreAuth from './pages/Auth/Restore';
import EventsProvider from './providers/events';
import { apiHost } from '@pv/common/api';
import OwnersProvider from './providers/owners';
import { MuiXLicense } from './components/MuiXLicense';
import FrigadeProviderWrapper from './components/Frigade/FrigadeProviderWrapper';
import { OnboardingProvider } from './providers/onboarding';

const pvEnv = process.env.REACT_APP_PV_ENV as string;
const API_HOST = apiHost(pvEnv);

let version = process.env.REACT_APP_RELEASE_VERSION;
version ||= 'dev';
console.log('RELEASE_VERSION', version);

const SetupPage = lazyImport(() => import('./pages/Setup'));
const PricingPage = lazyImport(() => import('./pages/Pricing'));
const CalendarPage = lazyImport(() => import('./pages/Calendar'));
const TasksPage = lazyImport(() => import('./pages/Tasks'));

const EventsPage = lazyImport(() => import('./pages/Events/List/index'));
const InboxPage = lazyImport(() => import('./pages/Events/Inbox'));

const EventPage = lazyImport(() => import('./pages/Events/Show'));

const ReportsPage = lazyImport(() => import('./pages/Reports'));
const ReportsAnalyticsPage = lazyImport(
  () => import('./pages/Reports/Analytics')
);
const ReportsEventsPage = lazyImport(() => import('./pages/Reports/Events'));
const ReportsPaymentsPage = lazyImport(
  () => import('./pages/Reports/Payments')
);
const ReportsProposalPage = lazyImport(
  () => import('./pages/Reports/Proposal')
);
const ReportsContactsPage = lazyImport(
  () => import('./pages/Reports/Contacts')
);
const ReportsAccountsPage = lazyImport(
  () => import('./pages/Reports/Accounts')
);
const ReportsSalesPage = lazyImport(() => import('./pages/Reports/Sales'));

const SettingsPage = lazyImport(() => import('./pages/Settings'));
const SettingsProposalPage = lazyImport(
  () => import('./pages/Settings/Proposal')
);
const SettingsTasksPage = lazyImport(() => import('./pages/Settings/Tasks'));
const DefaultSettingsPage = lazyImport(
  () => import('./pages/Settings/Default')
);
const SettingsEmailTemplatesPage = lazyImport(
  () => import('./pages/Settings/Email')
);
const SettingsVenueContactFormPage = lazyImport(
  () => import('./pages/Settings/VenueContactForm')
);

const GroupSettingsPage = lazyImport(
  () => import('./pages/Settings/GroupSettings')
);
const SettingsMenuPage = lazyImport(() => import('./pages/Settings/Menu'));
const SettingsVenuePage = lazyImport(() => import('./pages/Settings/Venue'));
const SettingsIntegrationsPage = lazyImport(
  () => import('./pages/Settings/Integrations')
);
const SettingsSpacesPage = lazyImport(() => import('./pages/Settings/Spaces'));
const SettingsTaxesAndFeesPage = lazyImport(
  () => import('./pages/Settings/TaxesAndFees')
);

const ExpressBookSettingsPage = lazyImport(
  () => import('./pages/Settings/ExpressBook')
);

const SettingsVenueProfilePage = lazyImport(
  () => import('./pages/Settings/VenueProfile')
);
const SettingsProfilePage = lazyImport(
  () => import('./pages/Settings/Profile')
);
const SettingsOrganizationPage = lazyImport(
  () => import('./pages/Settings/Organization')
);
const SettingsTeamMembersPage = lazyImport(
  () => import('./pages/Settings/TeamMembers')
);

const SettingsGroupContactFormPage = lazyImport(
  () => import('./pages/Settings/GroupContactForm')
);

const EventPageInfo = lazyImport(() => import('./events/EventDrawer'));
const EventPageEmail = lazyImport(
  () => import('./pages/Events/Show/EmailsTab')
);
const EventPageProposal = lazyImport(
  () => import('./pages/Events/Show/ProposalTab')
);
const EventPageNotes = lazyImport(() => import('./pages/Events/Show/NotesTab'));
const EventPagePayments = lazyImport(
  () => import('./pages/Events/Show/PaymentsTab')
);
const EventPageDocuments = lazyImport(
  () => import('./components/DocumentsTab')
);
const EventPageTasks = lazyImport(
  () => import('./pages/Events/Show/EventTasksTab')
);
const EventPageMessages = lazyImport(
  () => import('./pages/Events/Show/MessagesTab')
);
const EventPageFloorplan = lazyImport(
  () => import('./pages/Events/Show/FloorplanTab')
);
const VenueContactFormPage = lazyImport(
  () => import('@pv/common/pages/ContactForm/Venue')
);
const OrgContactFormPage = lazyImport(
  () => import('@pv/common/pages/ContactForm/Org')
);

const VenueCollectionContactFormPage = lazyImport(
  () => import('@pv/common/pages/ContactForm/VenueCollection')
);

const ProposalPage = lazyImport(() => import('./pages/Proposal'));
const Collections = lazyImport(() => import('./pages/Collections'));
const CollectionsList = lazyImport(
  () => import('./pages/Collections/pages/CollectionsList')
);
const CollectionResults = lazyImport(
  () => import('./pages/Collections/pages/CollectionResults')
);
const ShowcaseResults = lazyImport(
  () => import('./pages/Collections/pages/ShowcaseResults')
);
const SearchResults = lazyImport(
  () => import('./pages/Collections/pages/SearchResults')
);
const SpaceInfo = lazyImport(
  () => import('./pages/Collections/pages/SpaceInfo')
);

const OnboardingPage = lazyImport(
  () => import('./pages/Onboarding/OnboardingPage')
);

const GenerateBeoPage = lazyImport(
  () => import('./pages/Pdfs/GenerateBeoPage')
);

const ExpressBookPage = lazyImport(() => import('./pages/ExpressBook/List'));

const Providers = () => (
  <PublicConfigProvider apiHost={API_HOST}>
    <SentryUser />
    <ErrorBoundary>
      <ServerStateProvider apiHost={API_HOST}>
        <RemoteRefreshTokenWatcher />
        <HubSpot />
        <AuthUserProvider>
          <VenuesProvider>
            <ThemeProvider>
              <FrigadeProviderWrapper>
                <PVSnackbarProvider>
                  <DatePickerProvider>
                    <OnboardingProvider>
                      <FlashSnackbars>
                        <Suspense fallback={<PageLoading />}>
                          <Outlet />
                        </Suspense>
                      </FlashSnackbars>
                    </OnboardingProvider>
                  </DatePickerProvider>
                </PVSnackbarProvider>
              </FrigadeProviderWrapper>
            </ThemeProvider>
          </VenuesProvider>
        </AuthUserProvider>
      </ServerStateProvider>
    </ErrorBoundary>
    <MuiXLicense />
  </PublicConfigProvider>
);

export default function App() {
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <>
            <Route path="/" element={<Providers />}>
              <Route index element={<Navigate to="/events" />} />

              <Route element={<Auth />}>
                <Route path="/onboarding" element={<OnboardingPage />} />

                <Route element={<DashboardLayout />}>
                  <Route path="/setup" element={<SetupPage />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route
                    path="/calendar"
                    element={
                      <EventsProvider>
                        <OwnersProvider>
                          <CalendarPage />
                        </OwnersProvider>
                      </EventsProvider>
                    }
                  />
                  <Route path="/tasks" element={<TasksPage />} />
                  <Route path="/inbox" element={<InboxPage />} />
                  <Route path="/events">
                    <Route index element={<EventsPage />} />
                    <Route path=":id" element={<EventPage />}>
                      <Route path="info" element={<EventPageInfo />} />
                      <Route path="emails" element={<EventPageEmail />} />
                      <Route path="proposal" element={<EventPageProposal />} />
                      <Route path="notes" element={<EventPageNotes />} />
                      <Route path="payments" element={<EventPagePayments />} />
                      <Route
                        path="documents"
                        element={<EventPageDocuments />}
                      />
                      <Route path="tasks" element={<EventPageTasks />} />
                      <Route path="messages" element={<EventPageMessages />} />
                      <Route
                        path="floorplan"
                        element={<EventPageFloorplan />}
                      />
                    </Route>
                  </Route>

                  <Route path="express_book" element={<ExpressBookPage />} />

                  <Route path="/reports">
                    <Route index element={<ReportsPage />} />
                    <Route
                      path="analytics"
                      element={<ReportsAnalyticsPage />}
                    />
                    <Route path="events" element={<ReportsEventsPage />} />
                    <Route path="payments" element={<ReportsPaymentsPage />} />
                    <Route
                      path="proposal_data"
                      element={<ReportsProposalPage />}
                    />
                    <Route path="contacts" element={<ReportsContactsPage />} />
                    <Route path="accounts" element={<ReportsAccountsPage />} />
                    <Route path="sales" element={<ReportsSalesPage />} />
                  </Route>
                  <Route path="/settings" element={<SettingsPage />}>
                    <Route index element={<DefaultSettingsPage />} />
                    <Route
                      path="integrations"
                      element={<SettingsIntegrationsPage />}
                    />
                    <Route
                      path="venue_setup"
                      element={<Navigate to="/settings/integrations" replace />}
                    />
                    <Route path="venue" element={<SettingsVenuePage />} />
                    <Route
                      path="contact_form"
                      element={<SettingsVenueContactFormPage />}
                    />
                    <Route
                      path="group_settings"
                      element={<GroupSettingsPage />}
                    />
                    <Route path="proposal" element={<SettingsProposalPage />} />
                    <Route
                      path="email"
                      element={<SettingsEmailTemplatesPage />}
                    />
                    <Route
                      path="email_templates"
                      element={<Navigate to="/settings/email" replace />}
                    />
                    <Route path="tasks" element={<SettingsTasksPage />} />
                    <Route path="menu" element={<SettingsMenuPage />} />
                    <Route path="spaces" element={<SettingsSpacesPage />} />
                    <Route
                      path="taxes_and_fees"
                      element={<SettingsTaxesAndFeesPage />}
                    />
                    <Route
                      path="venue_profile"
                      element={<SettingsVenueProfilePage />}
                    />
                    <Route
                      path="express_book"
                      element={<ExpressBookSettingsPage />}
                    />
                    <Route
                      index
                      path="profile"
                      element={<SettingsProfilePage />}
                    />
                    <Route
                      path="account"
                      element={<Navigate to="/settings/profile" replace />}
                    />
                    <Route path="team" element={<SettingsTeamMembersPage />} />
                    <Route
                      path="organization"
                      element={<Navigate to="/settings/billing" />}
                    />
                    <Route
                      path="billing"
                      element={<SettingsOrganizationPage />}
                    />
                    <Route
                      path="group_contact_form"
                      element={<SettingsGroupContactFormPage />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path="/venues/:id/hello"
                element={<VenueContactFormPage />}
              />
              <Route
                path="/organizations/:id/hello"
                element={<OrgContactFormPage />}
              />
              <Route
                path="/concepts/:id/hello"
                element={<VenueCollectionContactFormPage />}
              />

              <Route path="/organizations/:id" element={<Collections />}>
                <Route index element={<ShowcaseResults />} />
                <Route path=":venueId/:spaceId" element={<SpaceInfo />} />
              </Route>

              <Route path="/search" element={<Collections />}>
                {/* TODO: create search 'landing page' */}
                <Route index element={<SearchResults />} />
                <Route path=":venueId/:spaceId" element={<SpaceInfo />} />
              </Route>

              <Route path="collections" element={<Collections />}>
                <Route index element={<CollectionsList />} />
                <Route path=":id/:venueId/:spaceId" element={<SpaceInfo />} />
                <Route path=":id" element={<CollectionResults />} />
              </Route>

              <Route path="/proposals/:id" element={<ProposalPage />} />
              <Route
                path="/pdfs/generate_beo/:eventId"
                element={<GenerateBeoPage />}
              />
              <Route element={<RestoreAuth />}>
                <Route path="/users/sign_in" element={<SignInPage />} />
                <Route path="/users/sign_up" element={<SignUpPage />} />
                <Route path="/users/new" element={<NewUserPage />} />
                <Route
                  path="/users/password/new"
                  element={<NewPasswordPage />}
                />
                <Route
                  path="/users/password/edit"
                  element={<EditPasswordPage />}
                />
                <Route
                  path="/users/password/instructions"
                  element={<PasswordInstructionsPage />}
                />
                <Route
                  path="/users/invitation/accept"
                  element={<AcceptInvitationPage />}
                />
              </Route>
              <Route path="/*" element={<Navigate to="/events" />} />
            </Route>
          </>
        )
      )}
    />
  );
}
